import PermissionsService from "./permissions.service";

class Permission {
  constructor(payload = {}) {
    Object.assign(this, {
      ...Permission.build(payload),
    });
  }

  static build({
    id = "",
    name = "",
    slug = "",
    description = "",
  }) {
    return {
      id,
      name,
      slug,
      description,
    };
  }

  static newData(payload) {
    return new Permission(payload);
  }

  static getAll(params) {
    return new Promise((resolve, reject) => {
      PermissionsService.getAll(params).then(
        (success) => {
          const { data } = success;
          const list = Object.assign({}, { ...data });
          list.data = data.data.map((item) => this.newData(item));
          resolve(list);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }
}

export default Permission;
