<template>
  <div>
    <div v-if="openDetails" class="mt-4 font-weight-bold">
      <v-icon @click="closeView()"> mdi-arrow-left </v-icon>&nbsp;
      <span style="margin-left: 0.5rem;">Voltar</span>
    </div>

    <toolbar :toolbarName="dynamicToolbarName" :openDetails="openDetails"/>

    <form-dialog :dialog="formDialog" :formName="dynamicFormName">
      <v-form ref="formAuthority">
        <v-row>
          <v-col cols="12" md="4" sm="6">
            <custom-label label="Nome"/>
            <simple-text-field v-model="payload.name"
                               required
                               placeholder="Nome"
            />
          </v-col>
          <v-col cols="12" md="4" sm="6">
            <custom-label label="Status"/>
            <simple-select :items="isActiveItems"
                           v-model="payload.is_active"
                           placeholder="Selecione o Status"
                           itemText="text"
                           itemValue="value"
                           :menuProps="{ bottom: true, offsetY: true, closeOnClick: true}"
                           required
                           :rules="requiredRules"
            />
          </v-col>
        </v-row>
        <v-spacer></v-spacer>
        <v-row>
          <v-card-title style="font-size: 14px;" class="font-weight-bold">Permissões</v-card-title>
          <v-container>
            <v-row>
              <v-col cols="4" v-for="permission in permissions" :key="permission.id">
                <v-checkbox
                  v-model="checkedPermissions[permission.id]"
                  class="my-2"
                  :label="permission.name"
                  color="#CFA545"
                ></v-checkbox>
                <v-divider></v-divider>
              </v-col>
            </v-row>
          </v-container>
        </v-row>
      </v-form>
    </form-dialog>

    <search-bar v-if="!openDetails" v-model="search"
      @externalFiltersClear="clearFilters" :noSecondaryInput="false">

      <simple-select
        v-model="isActiveSearch"
        :items="isActiveItems"
        @input="filterByStatus()"
        itemText="text"
        itemValue="value"
        class="mr-2"
        placeholder="Status"
        height="0"
        :menuProps="{
          bottom: true,
          offsetY: true,
          closeOnClick: true,
          maxHeight: 200,
        }"
      />
    </search-bar>

    <row-details v-if="openDetails">
      <v-row align="center">
        <v-col class="d-flex justify-start">
          <span class="font-weight-bold">Detalhes</span>
        </v-col>
        <v-spacer></v-spacer>
        <v-col class="d-flex justify-end">
          <v-icon @click="edit()"> mdi-pencil</v-icon>
        </v-col>
      </v-row>
      <v-row>
        <col-details v-if="payload.name != null" name="Nome" :item="payload.name"/>
        <col-details name="Status" :item="getStatus(payload.is_active)"/>
      </v-row>
    </row-details>

    <data-table :headers="headers"
                :fetching="fetching"
                :data="data"
                :search="search.text"
                :objectName="objectName"
                @loadAgain="loadAgain"
                v-else
    >
      <template v-slot:[`item.is_active`]="{ item }">
        {{ getStatus(item.is_active) }}
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-icon class="ml-4" small @click="view(item)">
          mdi-information-outline
        </v-icon>

        <v-icon class="ml-4" small @click="deleteItem(item)">
          mdi-trash-can-outline
        </v-icon>
      </template>
    </data-table>

    <confirm-destroy-dialog
      :title="payload.name"
      :dialog.sync="showConfirmDestroyDialog"
      @closeDialog="closeConfirmDestroyDialog"
    />

    <!-- Atualizar autoridade de usuários antes de remover autoridade -->
    <confirm-update-dialog :dialog="showConfirmUpdateDialog"
      :formName="payload.name" :textUpdate="'Prosseguir'">
      <v-row>
        <v-col cols="12">
          <v-toolbar elevation="0" style="background: none">
            <v-toolbar-title class="font-weight-bold"
              style="overflow: unset; white-space:unset;">
              {{ message }}
            </v-toolbar-title>
          </v-toolbar>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="4">
          <custom-label label="Função Administrativa" />
          <v-form ref="formUserAuthority">
            <simple-select
              :items="authoritiesFilter"
              v-model="selectedAuthority"
              itemText="name"
              itemValue="id"
              placeholder="Selecione uma função administrativa"
              :menuProps="{ bottom: true, offsetY: true, closeOnClick: true }"
              required
              :rules="requiredRules"
            />
          </v-form>
        </v-col>
      </v-row>

      <v-row>
        <data-table
          style="margin-left:12px;"
          :headers="headersUsersByAuthority"
          :data="usersByAuthority"
        />
      </v-row>
    </confirm-update-dialog>
  </div>
</template>

<script>
import DataTable from "@/components/data-table/DataTable";
import Toolbar from "@/components/crud/Toolbar";
import FormDialog from "@/components/crud/FormDialog";
import RowDetails from "@/components/crud/Details";
import Authorities from "@/domain/authorities/authorities";
import Permissions from "@/domain/permissions/permissions";
import Users from "@/domain/users/users";
import SearchBar from "@/components/layouts/SearchBar";
import SimpleTextField from "@/components/inputs/SimpleTextField.vue";
import SimpleSelect from "@/components/inputs/SimpleSelect";
import CustomLabel from "@/components/texts/CustomLabel";
import ColDetails from "@/components/crud/DetailsItem";
import ConfirmDestroyDialog from "@/components/dialogs/ConfirmDestroyDialog";
import ConfirmUpdateDialog from "@/components/crud/ConfirmUpdateDialog";

export default {
  name: 'Authorities',
  components: {
    DataTable,
    Toolbar,
    FormDialog,
    RowDetails,
    SearchBar,
    SimpleTextField,
    SimpleSelect,
    CustomLabel,
    ColDetails,
    ConfirmDestroyDialog,
    ConfirmUpdateDialog
  },
  data() {
    return {
      headers: [
        {text: "Função", value: "name"},
        {
          text: "Status",
          value: "is_active",
          filter: value => {
            if (this.isActiveSearch === "") return true;
            return value === this.search.is_active;
          },
        },
        {text: "Detalhes", value: "actions", sortable: false},
      ],
      headersUsersByAuthority: [
        {text: "Id", value: "id"},
        {text: "Name", value: "name"},
        {text: "Email", value: "email"},
        {text: "Autoridade", value:"authority.name"}
      ],
      isActiveItems: [
        {
          text: 'Ativo',
          value: true,
        },
        {
          text: 'Inativo',
          value: false,
        },
      ],

      objectName: 'funções administrativas',
      toolbarName: 'Funções Administrativas',
      formName: 'Adicionar Função Administrativa',

      fetching: true,
      search: {},
      isActiveSearch: '',
      data: [],
      permissions: [],

      authorityService: null,
      permissionService: null,
      userService: null,

      payload: null,
      checkedPermissions: [],
      selected: null,
      canToggleUser: false,

      openDetails: false,
      formDialog: false,
      showConfirmDestroyDialog: false,
      showConfirmUpdateDialog: false,

      usersByAuthority: [],
      authoritiesFilter: [],
      selectedAuthority: null,
      message: "Os usuários abaixo possuem vínculo com a função \
        administrativa que deseja-se excluir, para prosseguir com a deleção \
        por favor selecione uma nova autoridade para esses usuários.",

      requiredRules: [v => (!!v || v === false) || "O campo é obrigatório"]
    }
  },
  methods: {
    getAuthorities() {
      this.fetching = true;
      this.data     = [];

      this.authorityService
        .getHighLevel()
        .then((result) => {
          result.data.map((itm) => {
            this.data.push(itm);
          });
        })
        .catch(({response}) => {
          const {error} = response.data;
          this.$toasted.show(error, {
            type: "error",
          });
        });

      this.fetching = false;
    },
    getPermissions() {
      this.fetching    = true;
      this.permissions = [];

      this.permissionService.getAll().then((result) => {
        result.data.map((itm) => {
          this.permissions.push(itm);
        });
      });

      this.fetching = false;
    },
    async getUsersByAuthority() {
      let loader = this.$loading.show();
      this.usersByAuthority = [];

      await this.userService
      .getUsersByAuthority(this.payload.id).then((result) => {
        result.data.map((itm) => {
          this.usersByAuthority.push(itm);
        });
        loader.hide();
      })
      .catch(({response}) => {
        loader.hide();
        const {error} = response.data;
        this.$toasted.show(error, {
          type: "error",
        });
      });

      if (this.usersByAuthority.length !== 0) {
        this.showConfirmUpdateDialog = true;
      } else {
        this.showConfirmDestroyDialog = true;
      }
    },
    update() {
      if (!this.$refs.formUserAuthority.validate()) return;
      this.showConfirmDestroyDialog = true;
    },
    updateUsersAuthority() {
      let loader = this.$loading.show();

      this.userService
      .updateUsersAuthority(this.selectedAuthority, this.payload.id)
      .then(() => {
        loader.hide();
        this.$toasted.show("Autoridade dos usuários atualizado.", {
          type: 'success'
        });
      })
      .catch(({response}) => {
        loader.hide();
        const {error} = response.data;
        this.$toasted.show(error, {
          type: "error",
        });
      });
    },
    getStatus(status) {
      if (status === true) return "Ativo";

      return "Inativo";
    },
    initialize() {
      this.authorityService   = Authorities;
      this.permissionService  = Permissions;
      this.userService        = Users;
      this.payload            = this.authorityService.newData();

      this.getAuthorities();
      this.getPermissions();
    },
    view(item) {
      this.payload = Object.assign({}, item);
      this.selected = Object.assign({}, item);
      this.openDetails = true;

      for (let i = 0; i < this.payload.permissions.length; i++) {
        this.checkedPermissions[this.payload.permissions[i].id] =
          this.payload.permissions[i].pivot.is_enabled;
      }
    },
    closeDetails() {
      this.payload = {};
      this.selected = {};
      this.openDetails = false;
    },
    create() {
      this.payload = {};
      this.selected = {};
      this.canToggleUser = false;
      this.formDialog = true;

      for (let permission of this.permissions) {
        this.checkedPermissions[permission.id] = false;
      }
    },
    edit() {
      this.canToggleUser = true;
      this.formDialog = true;
    },
    deleteItem(authority) {
      this.payload = Object.assign({}, authority);

      this.selectedAuthority = null;
      this.authoritiesFilter = this.data;
      this.authoritiesFilter = this.authoritiesFilter.filter(
        item => item.id !== this.payload.id
      );

      this.getUsersByAuthority();
    },
    closeConfirmDestroyDialog() {
      this.showConfirmDestroyDialog = false;
    },
    closeConfirmUpdateDialog() {
      this.showConfirmUpdateDialog = false;
      this.toggleScrollBar('auto');
    },
    closeFormDialog() {
      this.selected = {};
      this.formDialog = false;
    },
    closeView() {
      this.openDetails = false;
      this.initialize();
    },
    save() {
      if (!this.$refs.formAuthority.validate()) {
        return;
      }

      let loader = this.$loading.show();

      this.payload.authority_id = this.authority_id;
      this.payload.permissions = this.checkedPermissions;

      if (!this.openDetails) {
        this.authorityService
          .create(this.payload)
          .then(() => {
            loader.hide();
            this.closeFormDialog();
            this.initialize();
            this.$toasted.show("Função cadastrada com sucesso.", {
              type: "success",
            });
          })
          .catch(({response}) => {
            loader.hide();
            const {error} = response.data;
            this.$toasted.show(error, {
              type: "error",
            });
          });
      } else {
        this.authorityService
          .edit(this.payload)
          .then(() => {
            loader.hide();
            this.closeFormDialog();
            this.$toasted.show("Função atualizada com sucesso.", {
              type: "success",
            });
          })
          .catch(({response}) => {
            loader.hide();
            const {error} = response.data;
            this.$toasted.show(error, {
              type: "error",
            });
          });
      }
    },
    async destroy() {
      if (this.showConfirmUpdateDialog) await this.updateUsersAuthority();

      await this.destroyAuthority();
    },
    destroyAuthority() {
      const loader = this.$loading.show();

      this.authorityService.delete(this.payload)
        .then(() => {
          loader.hide();
          this.showConfirmDestroyDialog = false;
          this.closeConfirmUpdateDialog();
          this.closeView();
          this.$toasted.show("Função removida com sucesso.", {
            type: "success",
          });
        })
        .catch(() => {
          loader.hide();
          this.showConfirmDestroyDialog = false;
          this.closeConfirmUpdateDialog();
          this.$toasted.show("Função não foi removida.", {
            type: 'error'
          });
        });
    },
    filterByStatus() {
      this.search.is_active = this.isActiveSearch;
    },
    clearFilters() {
      this.search         = {};
      this.isActiveSearch = "";
    },
    loadAgain() {
      this.initialize();
    },
    toggleScrollBar(param) {
      let html = document.querySelector('html');
      html.setAttribute("style", `overflow: ${param};`);
    }
  },
  computed: {
    canBeSortable() {
      return !this.fetching;
    },
    dynamicToolbarName() {
      if (this.openDetails) {
        return this.toolbarName + ' / Detalhes';
      }

      return this.toolbarName;
    },
    dynamicFormName() {
      if (this.openDetails) {
        return "Editar Função";
      }

      return this.formName;
    }
  },
  watch: {
    showConfirmDestroyDialog: {
      immediate: true,
      handler () {
        if (this.showConfirmUpdateDialog) this.toggleScrollBar('hidden');
      }
    }
  },
  beforeMount() {
    this.initialize();
  }
}
</script>
