import http from '@/services/http.service';

const apiUrl = '/permissions';

class PermissionsService {
    static getAll(params) {
        return http.get(`${apiUrl}`, { params });
    }
}

export default PermissionsService;
